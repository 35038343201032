import { projectsEn } from "../components/projects/config";
import { skillsEn } from "../components/skills/config";

export const text = {
  "en": {
    // projetcs page
    projects: projectsEn,
    projectsHeading: "Projects",
    projectsDescription: "Take a look at my blockchain projects:",
    projectsGithubText: "You can find my other projects on my GitHub",
    projectsMoreText: " . . . more coming soon!",
    projectsViewMore: "View More",
    projectsViewLess: "View Less",

    // skillset page
    skills: skillsEn,
    skillsetHeading: "Skillset",
    skillsetDescription: "Technologies I use in my development:",

    // contact page
    contactHeading: "Contact Me",

    // about page
    aboutHi: "Hello! 👋",
    aboutIam: "I'am ",
    aboutSkilled: "skilled",
    aboutText: "with +6 years of experience in blockchain development, enthusiastic about blockchain and web3 industry, constantly seeking opportunities for continuous learning and growth.",

    // others
    contactButton: "Contact Me",

    // terminal page
    terminalHeading: "Terminal",
    terminalDescription: "Use this interactive terminal to find out more about me and my work!"
  }
};